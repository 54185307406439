import React from 'react';

const BrokenLinkIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="26.5441" cy="28.8223" r="23" fill="#F0EC73"/>
            <path d="M39.9914 30.9558L47.7695 23.1777C52.0653 18.8819 52.0653 11.9171 47.7695 7.62132C43.4738 3.32555 36.509 3.32555 32.2132 7.62132L23.0208 16.8137C18.725 21.1095 18.725 28.0743 23.0208 32.3701C23.0208 32.3701 23.0208 32.3701 23.0208 32.3701C23.0208 32.3701 24.7886 34.1378 26.5563 34.4914M13.1213 26.7132L5.34313 34.4914C1.04736 38.7871 1.04736 45.752 5.34313 50.0477C9.6389 54.3435 16.6037 54.3435 20.8995 50.0477L30.0919 40.8553C34.3876 36.5596 34.3876 29.5948 30.0919 25.299C30.0919 25.299 30.0919 25.299 30.0919 25.299C30.0918 25.2989 28.3241 23.5312 26.5563 23.1777M35.6274 46.3909V53.3909M41.4056 40.8553L46.3553 45.8051M46.3909 35.8701H53.3909M9 19.7634H2M14.5355 13.9853L9.58579 9.03552M19.5208 9V2" stroke="black" strokeWidth="4" strokeLinecap="round"/>
        </svg>

    );
};

export default BrokenLinkIcon;
