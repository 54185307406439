import React from 'react';

export default () => {
    return (
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="50" height="50" rx="25" fill="#F0F9FF" />
            <path d="M25.5 32V18" stroke="#0072CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.5 25L25.5 18L32.5 25" stroke="#0072CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
