import React from 'react';

const PuzzleIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="puzzle-icon">
            <path d="M14.5299 2.77824C14.7477 3.35596 14.8199 3.95291 14.7645 4.52866C14.705 5.14897 15.4137 5.95438 15.9968 5.73449L23.2966 2.98162C23.6198 2.85973 23.9807 3.02294 24.1025 3.34616L26.8554 10.646C27.0753 11.2291 28.1481 11.2295 28.5746 10.7751C28.9705 10.3534 29.4664 10.0134 30.0441 9.79555C32.0642 9.03374 34.3194 10.0538 35.0812 12.0739C35.843 14.094 34.823 16.3492 32.8029 17.111C32.2252 17.3289 31.6282 17.401 31.0525 17.3457C30.4322 17.2861 29.6268 17.9948 29.8466 18.5779L32.5995 25.8777C32.7214 26.201 32.5582 26.5618 32.235 26.6837L24.3499 29.6572C23.7668 29.8771 23.0581 29.0717 23.1177 28.4514C23.173 27.8757 23.1009 27.2787 22.883 26.701C22.1212 24.6809 19.866 23.6608 17.8459 24.4226C15.8258 25.1845 14.8057 27.4396 15.5676 29.4598C15.7854 30.0375 16.1254 30.5334 16.5471 30.9292C17.0015 31.3557 17.0011 32.4286 16.418 32.6485L9.7034 35.1806C9.38019 35.3025 9.01936 35.1393 8.89747 34.8161L6.1446 27.5163C5.92471 26.9332 6.73012 26.2245 7.35044 26.2841C7.92618 26.3394 8.52313 26.2672 9.10085 26.0494C11.121 25.2876 12.141 23.0324 11.3792 21.0123C10.6174 18.9922 8.3622 17.9721 6.34209 18.7339C5.76437 18.9518 5.26844 19.2918 4.87259 19.7135C4.4461 20.1679 3.37325 20.1674 3.15336 19.5844L0.400495 12.2845C0.278605 11.9613 0.441812 11.6005 0.765029 11.4786L8.06485 8.72573C8.64794 8.50584 8.64837 7.433 8.194 7.0065C7.77229 6.61065 7.43228 6.11472 7.21442 5.537C6.45261 3.5169 7.47266 1.26171 9.49276 0.4999C11.5129 -0.26191 13.7681 0.758139 14.5299 2.77824Z" fill="#FFBDBE"/>
        </svg>
    );
};

export default PuzzleIcon;
