import React from 'react';

const MoonPayIcon = ({ color }) => {
    const fill = color || 'white';
    return (
        <svg width="110" height="30" viewBox="0 0 110 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip2342342340)">
                <path d="M37.2605 21.7442H39.4881V13.4569L42.9192 21.7442H44.7158L48.129 13.4569V21.7442H50.3566V9.24185H47.626L43.8176 18.4757L40.0093 9.24185H37.2605V21.7442Z" fill={fill}/>
                <path d="M56.4513 21.8871C59.1998 21.8871 61.1759 19.9224 61.1759 17.2434C61.1759 14.5643 59.1998 12.5996 56.4513 12.5996C53.685 12.5996 51.7089 14.5643 51.7089 17.2434C51.7089 19.9224 53.685 21.8871 56.4513 21.8871ZM56.4513 19.9939C54.9422 19.9939 53.9365 18.8687 53.9365 17.2434C53.9365 15.6181 54.9422 14.4929 56.4513 14.4929C57.9425 14.4929 58.9483 15.6181 58.9483 17.2434C58.9483 18.8687 57.9425 19.9939 56.4513 19.9939Z" fill={fill}/>
                <path d="M66.5653 21.8871C69.3138 21.8871 71.2899 19.9224 71.2899 17.2434C71.2899 14.5643 69.3138 12.5996 66.5653 12.5996C63.799 12.5996 61.8229 14.5643 61.8229 17.2434C61.8229 19.9224 63.799 21.8871 66.5653 21.8871ZM66.5653 19.9939C65.0562 19.9939 64.0505 18.8687 64.0505 17.2434C64.0505 15.6181 65.0562 14.4929 66.5653 14.4929C68.0565 14.4929 69.0623 15.6181 69.0623 17.2434C69.0623 18.8687 68.0565 19.9939 66.5653 19.9939Z" fill={fill}/>
                <path d="M72.4216 21.7442H74.6314V16.8326C74.6314 15.5109 75.4936 14.5464 76.733 14.5464C77.8648 14.5464 78.6193 15.4037 78.6193 16.6361V21.7442H80.8288V16.1717C80.8288 14.0642 79.4996 12.5996 77.4516 12.5996C76.1583 12.5996 75.1345 13.1533 74.6314 14.1356V12.7425H72.4216V21.7442Z" fill={fill}/>
                <path d="M82.6636 21.7442H85.0529V16.9933H87.7116C90.3344 16.9933 91.8972 15.3858 91.8972 13.1176C91.8972 10.8493 90.3344 9.24185 87.7116 9.24185H82.6636V21.7442ZM85.0529 15.0108V11.2244H87.6037C89.0049 11.2244 89.5798 12.1174 89.5798 13.1176C89.5798 14.1178 89.0049 15.0108 87.6037 15.0108H85.0529Z" fill={fill}/>
                <path d="M96.1395 12.5996C93.7861 12.5996 92.2592 13.9928 92.1512 15.7788H94.1274C94.1633 14.9751 94.8641 14.2428 96.0315 14.2428C97.1993 14.2428 97.8819 14.8679 97.8819 15.7431C97.8819 16.1003 97.6664 16.3503 97.2171 16.3503H95.5467C93.5167 16.3503 92.1512 17.4398 92.1512 19.1901C92.1512 20.744 93.3728 21.8871 95.1694 21.8871C96.4629 21.8871 97.5046 21.2977 97.8819 20.4047V21.7442H100.056V15.9574C100.056 13.9213 98.4387 12.5996 96.1395 12.5996ZM95.6902 20.2618C94.8641 20.2618 94.2891 19.7974 94.2891 19.1009C94.2891 18.315 94.9179 17.7971 95.852 17.7971H97.8819V17.9399C97.8819 19.2973 97.0736 20.2618 95.6902 20.2618Z" fill={fill}/>
                <path d="M109 12.7425H106.736L104.653 18.9223L102.551 12.7425H100.305L103.611 21.7442L102.335 25.3877H104.437L105.838 21.3155L109 12.7425Z" fill={fill}/>
                <path d="M24.5 18C24.5 24.6274 19.1274 30 12.5 30C5.87258 30 0.5 24.6274 0.5 18C0.5 11.3726 5.87258 6 12.5 6C19.1274 6 24.5 11.3726 24.5 18Z" fill={fill}/>
                <path d="M32.5 4.49999C32.5 6.98527 30.4853 9 28 9C25.5147 9 23.5 6.98527 23.5 4.49999C23.5 2.01471 25.5147 0 28 0C30.4853 0 32.5 2.01471 32.5 4.49999Z" fill={fill}/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="109" height="30" fill={fill} transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default MoonPayIcon;
